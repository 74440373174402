import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';

import { mapGetters, mapState } from 'vuex';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { insufficientPermissionsMessage } from '../../../shared/helpers/permissionsUtils';
import { postAssignTeachersToGroup } from '../../helpers/actionsGroupsOptions';
import {
  fetchEmployee,
  fetchEmployeeByName,
} from '../../helpers/employeeOptions';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { fetchSchoolCycles } from '../../../configuration/helpers/KnGroupsOptions';
import { fetchSchoolLevels } from '../../helpers/syllabusOptions';
import { fetchTypesEmployee } from '../../../configuration/helpers/KnEmployeeTypesOptions';
import { fetchGroups } from '../../helpers/reportCardOptions';

export default {
  name: 'KnFormAssignTeacherToGroup',
  components: {
    KnAutoComplete,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormTitle,
    KnLocalAlert,
    KnTabs,
  },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      tabs: [{ name: 'Asignación de grupo', value: 100 }],
      valueDeterminate: 50,
      routerName: 'Acciones Grupos',
      resource: 'grupo',
      bkEntity: null,
      bkReadonly: false,
      cycles: [],
      levels: [],
      groups: [],
      teachers: [],
      selectedSchoolYear: null,
      selectedSchoolLevel: null,
      selectedGroup: null,
      selectedTeacher: null,
      loadingGroups: false,
      teacherType: null,
      selectedTeachers: [],
      assignmentData: [],
      loadingEmployees: false,
      inputTeacher: [],
      teacherPagination: {},
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['currentSchoolYear', 'currentSchoolLevel']),
    isNewMode() {
      return !this.entity || !this.bkEntity;
    },
    title() {
      return this.isNewMode
        ? 'Asignación de profesores'
        : 'Reasignación de profesores';
    },
    isReadonly() {
      return this.readonly || this.bkReadonly;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Profesores asignados con éxito!'
        : 'Profesores reasignados con éxito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
    selectedSchoolLevelName() {
      let schoolLevelName = null;
      if (this.selectedSchoolLevel) {
        const findedLevel = this.levels.find(
          (l) => l.id === this.selectedSchoolLevel
        );
        if (findedLevel) {
          schoolLevelName = findedLevel.nombre;
        }
      }
      return schoolLevelName;
    },
    selectedGroupObj() {
      return this.selectedGroup
        ? this.groups.find((g) => g.id === this.selectedGroup)
        : null;
    },
    selectedTeacherObj() {
      return this.selectedTeacher
        ? this.teachers.find((t) => t.id === this.selectedTeacher)
        : null;
    },
    selectedTeachersObj() {
      return this.selectedTeachers.length
        ? this.teachers.filter((t) => this.selectedTeachers.includes(t.id))
        : [];
    },
  },
  async created() {
    this.loading = true;

    this.bkEntity = this.entity;
    this.bkReadonly = this.readonly;

    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;
    Object.assign(this.teacherPagination, this.pagination);
    await this.fetchData();

    this.showAlert = false;
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    getFullName: getFullName,
    async fetchData() {
      // Se deben obtener los valores necesarios para
      // llenar los selects

      const { ok: okSchoolCycles, data: dataSchoolCycles } =
        await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 1000,
        });
      const { ok: okSchoolLevels, data: dataSchoolLevels } =
        await fetchSchoolLevels({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okTypeEmployee, data: dataTypeEmployee } =
        await fetchTypesEmployee({
          dato: 'docente',
          institutionId: this.institutionId,
          systemStatus: true,
        });
      this.teacherType = okTypeEmployee
        ? dataTypeEmployee.length
          ? dataTypeEmployee[0]
          : null
        : null;
      const { ok: okEmployee, data: dataEmployee } = await fetchEmployee({
        institutionId: this.institutionId,
        typeEmployee: this.teacherType ? this.teacherType.id : null,
        systemStatus: true,
        limit: 1000,
      });

      this.cycles = okSchoolCycles ? dataSchoolCycles : [];
      this.levels = okSchoolLevels ? dataSchoolLevels : [];
      this.teachers = okEmployee ? dataEmployee : [];
    },
    async setSchoolYears() {
      const { ok: okSchoolCycles, data: dataSchoolCycles } =
        await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      this.cycles = okSchoolCycles ? dataSchoolCycles : [];
      this.selectedSchoolYear = this.currentSchoolYear;
    },
    async setSchoolLevels() {
      const { ok: okSchoolLevels, data: dataSchoolLevels } =
        await fetchSchoolLevels({
          institutionId: this.institutionId,
          name:
            this.currentSchoolLevel !== 'Todos'
              ? this.currentSchoolLevel
              : null,
          systemStatus: true,
          limit: 100,
        });

      this.levels = okSchoolLevels ? dataSchoolLevels : [];
      this.selectedSchoolLevel =
        this.currentSchoolLevel !== 'Todos' && this.levels.length
          ? this.levels[0]
          : null;
    },
    async getGroups() {
      if (!this.isReadonly) {
        if (!this.loadingGroups) {
          this.loadingGroups = true;

          const { ok, data, message } = await fetchGroups({
            institutionId: this.institutionId,
            schoolCycleId: this.selectedSchoolYear
              ? this.selectedSchoolYear
              : undefined,
            // syllabusId: this.selectedSyllabus,
            schoolLevelId: this.selectedSchoolLevel,
            systemStatus: true,
            limit: 1000,
          });
          if (ok) {
            this.groups = data;
          } else {
            console.log('Error al obtener grupos', message);
          }
          this.loadingGroups = false;
        }
      }
    },
    async searchEmployee() {
      try {
        if (!this.loadingEmployees) {
          this.loadingEmployees = true;
          this.teachers = [];
          if (this.inputTeacher && this.inputTeacher !== '') {
            const { ok, data } = await fetchEmployeeByName(
              this.inputTeacher,
              this.teacherPagination,
              true,
              this.institutionId,
              this.teacherType ? this.teacherType.id : null
            );
            // console.log('data fetchEmployeeByName', data.results);

            this.teachers = ok ? data.results : [];
            // console.log('this.teachers', this.teachers);
          } else {
            const { ok: okEmployee, data: dataEmployee } = await fetchEmployee({
              institutionId: this.institutionId,
              typeEmployee: this.teacherType ? this.teacherType.id : null,
              systemStatus: true,
              limit: this.teacherPagination.limit,
            });
            this.teachers = okEmployee ? dataEmployee : [];
          }
        }
      } catch (error) {
        console.log('Error al intentar obtener empleados', error);
      } finally {
        this.loadingEmployees = false;
      }
    },
    addTeachers() {
      const item = {
        group: this.selectedGroupObj,
        teachers: this.selectedTeachersObj,
      };
      this.assignmentData.push(item);
      this.clearSelection();
    },
    clearSelection() {
      this.selectedGroup = null;
      this.selectedTeachers = [];
    },
    async assignTeachersToGroup() {
      for (const item of this.assignmentData) {
        const { group, teachers } = item;
        const { ok, message } = await postAssignTeachersToGroup({
          id_grupo: group.id,
          ids_profesores: teachers.map((t) => t.id),
        });
        if (ok) {
          this.alertText =
            'Profesores asignados correctamente a ' + group.nombre_grupo;
        } else {
          this.warnings.push(
            'Error al asignar profesores a grupo ' +
              group.nombre_grupo +
              ': ' +
              message
          );
        }
      }
    },
    async save() {
      this.loading = true;
      this.alertColor = 'info';
      this.alertText = 'Cargando...';
      this.showAlert = true;
      if (this.isNewMode) {
        await this.assignTeachersToGroup();
      }
      this.completeAlert();
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.selectedSchoolYear = null;
      this.selectedSchoolLevel = null;
      this.selectedTeacher = null;
      this.selectedGroup = null;
      this.groups = [];
    },
    removeChip(id) {
      this.syllabus.materias_curriculares =
        this.syllabus.materias_curriculares.filter((subject) => subject !== id);
    },
    removeTeacherChip(mainIndex, teacherId) {
      console.log('removeTeacherChip', mainIndex, teacherId);

      this.assignmentData[mainIndex].teachers = this.assignmentData[
        mainIndex
      ].teachers.filter((t) => t.id !== teacherId);

      if (!this.assignmentData[mainIndex].teachers.length) {
        const groupId = this.assignmentData[mainIndex].group.id;
        this.assignmentData = this.assignmentData.filter(
          (item) => item.group.id !== groupId
        );
      }
    },
  },
};
