import { mapGetters } from 'vuex';
import KnFormAssignTeacherAndSubjectToGroup from '../KnFormAssignTeacherAndSubjectToGroup/KnFormAssignTeacherAndSubjectToGroup.vue';
import KnFormAssignTeacherToGroup from '../KnFormAssignTeacherToGroup/KnFormAssignTeacherToGroup.vue';

export default {
  name: 'KnFormAssignSubjectTeacher',
  components: {
    KnFormAssignTeacherAndSubjectToGroup,
    KnFormAssignTeacherToGroup,
  },
  mixins: [],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['schoolControlEnabled']),
  },
  async created() {},
  methods: {},
};
