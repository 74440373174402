import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';
import { formatStringDateToLocaleDate } from '../../shared/helpers/dateUtils';

export const fetchSubjectsTeacher = async ({
  teacherId = null,
  subjectId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const systemStatusFilter =
      systemStatus !== null ? `estatus_sistema=${systemStatus}` : '';
    const teacherIdFilter = teacherId !== null ? `&profesor=${teacherId}` : '';
    const subjectIdFilter = subjectId !== null ? `&materia=${subjectId}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/materia-profesor?'.concat(
        systemStatusFilter,
        teacherIdFilter,
        subjectIdFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las materiaProfesor. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener materiaProfesor. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener materiaProfesor. ' + error,
      count: 0,
    };
  }
};

export const fetchSubjectTeacher = async (id) => {
  try {
    const response = await getItem(`/app-personas/materia-profesor/${id}`);
    const { e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudo obtener la materiaProfesor. ' + e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    console.error('Error al obtener materiaProfesor. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener materiaProfesor. ' + error,
    };
  }
};

export const postSubjectTeacher = async (subjectTeacher) => {
  try {
    const response = await postItem(
      '/app-personas/materia-profesor',
      subjectTeacher
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear la materiaProfesor. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear materiaProfesor. ' + error,
    };
  }
};

export const putSubjectTeacher = async (subjectTeacher) => {
  try {
    const { id } = subjectTeacher;
    const response = await putItem(
      `/app-personas/materia-profesor/${id}`,
      subjectTeacher
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar la materiaProfesor. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar materiaProfesor. ' + error,
    };
  }
};

export const delSubjectTeacher = async (id) => {
  try {
    const response = await deleteItem('/app-personas/materia-profesor', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar la materiaProfesor. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al eliminar materiaProfesor' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar materiaProfesor. ' + error,
    };
  }
};

export const reactivateSubjectTeacher = async (subjectTeacherId) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/reactivar-materia-profesor',
      {
        id_anuncio: subjectTeacherId,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo reactivar la materiaProfesor. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear materiaProfesor. ' + error,
    };
  }
};
export const postGroup = async (group) => {
  try {
    const response = await postItem('/app-personas/grupo', group);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear grupo. ' + error,
    };
  }
};
export const putGroup = async (group) => {
  try {
    const { id } = group;
    const response = await putItem(`/app-personas/grupo/${id}`, group);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar grupo. ' + error,
    };
  }
};
export const deleteGroup = async (groupId) => {
  try {
    const response = await deleteItem('/app-personas/grupo', groupId);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar grupo. ' + error,
    };
  }
};

export const reactivateGroup = async (groupId) => {
  try {
    const response = await postItem('/app-personas/helpers/reactivar-grupo', {
      id_grupo: groupId,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo reactivar el grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar reactivar grupo. ' + error,
    };
  }
};

export const postEvaluationCriteria = async (criteria) => {
  try {
    const response = await postItem(
      '/app-personas/criterio-evaluacion',
      criteria
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el criterio de evaluación. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear el criterio de evaluación. ' + error,
    };
  }
};

export const postCreateSubjectStudentFromTeacherAssignement = async (
  groupId = null,
  subjectTeacherIds = []
) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/crear-materias-alumno-asignar-profesor',
      {
        id_grupo: groupId,
        ids_materias_profesores: subjectTeacherIds,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudieron crear las materiasAlumno. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear materiasAlumno. ' + error,
    };
  }
};

export const createEvaluationCriteria = async (criteriaForCloning = []) => {
  try {
    let createdCriteria = [];
    for (const criteria of criteriaForCloning) {
      const item = {
        nombre: criteria.nombre,
        descripcion: criteria.descripcion,
        porcentaje: criteria.porcentaje,
        es_recurrente: false,
        estatus_sistema: true,
      };
      const { ok, data, message } = await postEvaluationCriteria(item);
      if (ok) {
        createdCriteria.push(data.id);
      } else {
        console.log('No se pudo crear el criterio de evaluacion', message);
      }
      return createdCriteria;
    }
  } catch (error) {
    return [];
  }
};

export const createSubjectTeacher = async (
  selectedTeacherId = null,
  selectedSubjectsObj = [],
  institutionId
) => {
  try {
    console.log('profesor seleccionado', selectedTeacherId);
    console.log('materias a asignar', selectedSubjectsObj);
    const errors = [];
    const createdSubjectsTeacher = [];
    for (const subject of selectedSubjectsObj) {
      const evaluationCriteria = await createEvaluationCriteria(
        subject.criterios_evaluacion
      );
      const subjectTeacher = {
        profesor: selectedTeacherId,
        materia: subject.id,
        fecha_asignacion: formatStringDateToLocaleDate({
          date: new Date(),
        }),
        criterios_evaluacion: evaluationCriteria,
        institucion_educativa: institutionId,
        estatus_sistema: true,
      };
      const { ok, data, message } = await postSubjectTeacher(subjectTeacher);
      if (ok) {
        createdSubjectsTeacher.push(data.id);
      } else {
        errors.push(`Error asignación materia ${subject.nombre} - ${message}`);
      }
    }
    return {
      ok: true,
      createdSubjectsTeacher: createdSubjectsTeacher,
      errors: errors,
    };
  } catch (error) {
    return {
      ok: false,
      createdSubjectsTeacher: [],
      errors: [error],
    };
  }
};
