import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
// import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnSubjectsAutocomplete from '../KnSubjectsAutocomplete/KnSubjectsAutocomplete.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';

import { mapGetters, mapState } from 'vuex';

import { fetchSchoolCycles } from '../../../configuration/helpers/KnGroupsOptions';
import {
  fetchSchoolLevels,
  // fetchSubjects,
  fetchSyllabus,
  postAssignSyllabusToGroup,
} from '../../helpers/syllabusOptions';
import { fetchGroups } from '../../helpers/reportCardOptions';
import { fetchEmployee } from '../../helpers/employeeOptions';
import { fetchTypesEmployee } from '../../../configuration/helpers/KnEmployeeTypesOptions';
import { insufficientPermissionsMessage } from '../../../shared/helpers/permissionsUtils';
import { formatStringDateToLocaleDate } from '../../../shared/helpers/dateUtils';
import {
  postEvaluationCriteria,
  putGroup,
  postSubjectTeacher,
  postCreateSubjectStudentFromTeacherAssignement,
} from '../../helpers/subjectsTeacherOptions';
import { getFullName } from '../../../shared/helpers/dataUtils';

export default {
  name: 'KnFormAssignTeacherAndSubjectToGroup',
  components: {
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormTitle,
    KnLocalAlert,
    KnAutoComplete,
    KnSubjectsAutocomplete,
    KnTabs,
  },
  mixins: [],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [{ name: 'Asignación de materias', value: 100 }],
      valueDeterminate: 50,
      routerName: 'Profesores',
      resource: 'materiasprofesor',
      bkEntity: null,
      bkReadonly: false,
      subjectTeacher: {
        profesor: null,
        materia: null,
        criterios_evaluacion: [],
        institucion_educativa: null,
        estatus_sistema: true,
      },
      selectedSubjects: [],
      bkSelectedSubjects: [],
      cycles: [],
      levels: [],
      syllabus: [],
      groups: [],
      teachers: [],
      subjects: [],
      selectedSchoolYear: null,
      selectedSchoolLevel: null,
      selectedSyllabus: null,
      selectedGroup: null,
      selectedTeacher: null,
      loadingGroups: false,
      teacherType: null,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      syllabusMessage: [],
      createdSubjectsTeacher: [],
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['currentSchoolYear', 'currentSchoolLevel']),
    isNewMode() {
      return this.entity === null || this.bkEntity === null;
    },
    title() {
      return this.isNewMode
        ? 'Asignación profesores'
        : 'Reasignación profesores';
    },
    isReadonly() {
      return this.readonly || this.bkReadonly;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Materias asignadas con exito!'
        : 'Materias reasignadas con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
    selectedSchoolLevelName() {
      let schoolLevelName = null;
      if (this.selectedSchoolLevel) {
        const findedLevel = this.levels.find(
          (l) => l.id === this.selectedSchoolLevel
        );
        if (findedLevel) {
          schoolLevelName = findedLevel.nombre;
        }
      }
      return schoolLevelName;
    },
    selectedGroupObj() {
      return this.selectedGroup
        ? this.groups.find((g) => g.id === this.selectedGroup)
        : null;
    },
    selectedTeacherObj() {
      return this.selectedTeacher
        ? this.teachers.find((t) => t.id === this.selectedTeacher)
        : null;
    },
    selectedSubjectsObj() {
      return this.selectedSubjects.length
        ? this.subjects.filter((s) => this.selectedSubjects.includes(s.id))
        : [];
    },
  },
  async created() {
    this.loading = true;

    this.bkEntity = this.entity;
    this.bkReadonly = this.readonly;

    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;

    await this.fetchData();

    this.showAlert = false;
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    getFullName: getFullName,
    async fetchData() {
      // Se deben obtener los valores necesarios para
      // llenar los selects

      const { ok: okSchoolCycles, data: dataSchoolCycles } =
        await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 1000,
        });
      const { ok: okSchoolLevels, data: dataSchoolLevels } =
        await fetchSchoolLevels({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okSyllabus, data: dataSyllabus } = await fetchSyllabus({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 2000,
      });
      const { ok: okTypeEmployee, data: dataTypeEmployee } =
        await fetchTypesEmployee({
          dato: 'docente',
          institutionId: this.institutionId,
          systemStatus: true,
        });
      this.teacherType = okTypeEmployee
        ? dataTypeEmployee.length
          ? dataTypeEmployee[0]
          : null
        : null;
      const { ok: okEmployee, data: dataEmployee } = await fetchEmployee({
        institutionId: this.institutionId,
        typeEmployee: this.teacherType ? this.teacherType.id : null,
        systemStatus: true,
        limit: 1000,
      });
      // const { ok: okSubjects, data: dataSubjects } = await fetchSubjects({
      //   institutionId: this.institutionId,
      //   systemStatus: true,
      //   limit: 2000,
      // });

      this.cycles = okSchoolCycles ? dataSchoolCycles : [];
      this.levels = okSchoolLevels ? dataSchoolLevels : [];
      this.syllabus = okSyllabus ? dataSyllabus : [];
      // this.subjects = okSubjects ? dataSubjects : [];
      this.teachers = okEmployee ? dataEmployee : [];
    },
    async setSchoolYears() {
      const { ok: okSchoolCycles, data: dataSchoolCycles } =
        await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      this.cycles = okSchoolCycles ? dataSchoolCycles : [];
      this.selectedSchoolYear = this.currentSchoolYear;
    },
    async setSchoolLevels() {
      const { ok: okSchoolLevels, data: dataSchoolLevels } =
        await fetchSchoolLevels({
          institutionId: this.institutionId,
          name:
            this.currentSchoolLevel !== 'Todos'
              ? this.currentSchoolLevel
              : null,
          systemStatus: true,
          limit: 100,
        });

      this.levels = okSchoolLevels ? dataSchoolLevels : [];
      this.selectedSchoolLevel =
        this.currentSchoolLevel !== 'Todos' && this.levels.length
          ? this.levels[0]
          : null;
    },
    async setSyllabus() {
      this.loadingGroups = true;
      const { ok: okSyllabus, data: dataSyllabus } = await fetchSyllabus({
        institutionId: this.institutionId,
        schoolCycleId: this.selectedSchoolYear,
        schoolLevel: this.selectedSchoolLevelName,
        systemStatus: true,
        limit: 2000,
      });
      this.syllabus = okSyllabus ? dataSyllabus : [];

      if (this.selectedSchoolLevel) {
        await this.getGroups();
      }
      this.loadingGroups = false;
    },
    async setSubjects() {
      this.syllabusMessage = [];
      this.loadingGroups = true;
      if (this.selectedGroupObj) {
        if (this.selectedGroupObj.plan_estudios) {
          this.selectedSyllabus = null;
          const syllabus = this.selectedGroupObj.plan_estudios;
          const allSubjects = [
            ...syllabus.materias_curriculares,
            ...syllabus.materias_extracurriculares,
          ];
          this.selectedSyllabus = syllabus.id;
          this.subjects = allSubjects;
        } else if (this.selectedSyllabus) {
          const selectedSyllabus = this.syllabus.find(
            (s) => s.id === this.selectedSyllabus
          );
          const allSubjects = [
            ...selectedSyllabus.materias_curriculares,
            ...selectedSyllabus.materias_extracurriculares,
          ];
          this.subjects = allSubjects;
        } else {
          this.syllabusMessage =
            'Por favor, selecciona un plan de estudios o crea uno. El plan es necesario para obtener las materias';
          this.subjects = [];
        }
      }
      // const selectedGroup = this.groups.find(
      //   (g) => g.id === this.selectedGroup
      // );
      // if (selectedGroup && selectedGroup.plan_estudios) {
      //   const syllabus = selectedGroup.plan_estudios;
      //   const allSubjects = [
      //     ...syllabus.materias_curriculares,
      //     ...syllabus.materias_extracurriculares,
      //   ];
      //   this.subjects = allSubjects;
      // } else if (this.selectedSyllabus) {
      //   const selectedSyllabus = this.syllabus.find(
      //     (s) => s.id === this.selectedSyllabus
      //   );
      //   const allSubjects = [
      //     ...selectedSyllabus.materias_curriculares,
      //     ...selectedSyllabus.materias_extracurriculares,
      //   ];
      //   this.subjects = allSubjects;
      // } else {
      //   this.syllabusMessage =
      //     'Por favor, selecciona un plan de estudios o crea uno';
      //   this.subjects = [];
      // }
      this.loadingGroups = false;
    },
    async getGroups() {
      if (!this.isReadonly) {
        if (!this.loadingGroups) {
          this.loadingGroups = true;

          if (this.selectedSchoolYear) {
            await this.setSyllabus();
          }
          const { ok, data, message } = await fetchGroups({
            institutionId: this.institutionId,
            schoolCycleId: this.selectedSchoolYear
              ? this.selectedSchoolYear
              : undefined,
            // syllabusId: this.selectedSyllabus,
            schoolLevelId: this.selectedSchoolLevel,
            systemStatus: true,
            limit: 1000,
          });
          if (ok) {
            this.groups = data;
          } else {
            console.log('Error al obtener grupos', message);
          }
          this.loadingGroups = false;
        }
      }
    },
    async save() {
      this.loading = true;
      this.alertText = 'Asignando profesor y materias a grupo';
      this.showAlert = true;
      if (this.isNewMode) {
        await this.createSubjectTeacher();
        if (!this.errors.length) {
          await this.assignSubjectTeacherDataToGroup();
          if (!this.errors.length) {
            await this.assignSyllabusToGroup();
            await this.createSubjectsStudent();
          }
        }
      }
      this.completeAlert();
    },
    async createSubjectTeacher() {
      try {
        console.log('grupo seleccionado', this.selectedGroup);
        console.log('grupo seleccionado objeto', this.selectedGroupObj);

        console.log('profesor seleccionado', this.selectedTeacher);
        console.log('profesor seleccionado objeto', this.selectedTeacherObj);
        console.log('materias a asignar', this.selectedSubjects);
        console.log('materias a subjects', this.selectedSubjectsObj);
        for (const subject of this.selectedSubjectsObj) {
          const evaluationCriteria = await this.createEvaluationCriteria(
            subject.criterios_evaluacion
          );
          const subjectTeacher = {
            profesor: this.selectedTeacher,
            materia: subject.id,
            fecha_asignacion: formatStringDateToLocaleDate({
              date: new Date(),
            }),
            criterios_evaluacion: evaluationCriteria,
            institucion_educativa: this.institutionId,
            estatus_sistema: true,
          };
          const { ok, data, message } = await postSubjectTeacher(
            subjectTeacher
          );
          if (ok) {
            this.createdSubjectsTeacher.push(data.id);
          } else {
            this.errors.push(
              `Error asignación materia ${subject.nombre} - ${message}`
            );
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar asignar materias. ' + error);
      }
    },
    async createEvaluationCriteria(criteriaForCloning = []) {
      try {
        let createdCriteria = [];
        for (const criteria of criteriaForCloning) {
          const item = {
            nombre: criteria.nombre,
            descripcion: criteria.descripcion,
            porcentaje: criteria.porcentaje,
            es_recurrente: false,
            estatus_sistema: true,
          };
          const { ok, data, message } = await postEvaluationCriteria(item);
          if (ok) {
            createdCriteria.push(data.id);
          } else {
            console.log('No se pudo crear el criterio de evaluacion', message);
          }
          return createdCriteria;
        }
      } catch (error) {
        return [];
      }
    },
    async assignSubjectTeacherDataToGroup() {
      /**
       * Obtenemos los ids de los arreglos del
       * grupo seleccionado y les agregamos los
       * creados en este proceso
       */
      console.log('selectedGroupObj', this.selectedGroupObj);
      console.log('selectedTeacher', this.selectedTeacher);

      const teachers =
        this.selectedGroupObj && this.selectedGroupObj.profesores.length
          ? [
              ...this.selectedGroupObj.profesores.map((p) => p.id),
              this.selectedTeacher,
            ]
          : [this.selectedTeacher];
      console.log('teachers', teachers);

      console.log('createdSubjectsTeacher', this.createdSubjectsTeacher);
      const subjectsTeacher =
        this.selectedGroupObj && this.selectedGroupObj.materia_profesor.length
          ? [
              ...this.selectedGroupObj.materia_profesor.map((mp) => mp.id),
              ...this.createdSubjectsTeacher,
            ]
          : [...this.createdSubjectsTeacher];
      console.log('subjectsTeacher', subjectsTeacher);

      const students =
        this.selectedGroupObj && this.selectedGroupObj.alumnos.length
          ? [...this.selectedGroupObj.alumnos.map((a) => a.id)]
          : [];
      console.log('students', students);

      /**
       * Para asignar el profesor y las materiasProfesor nuevas
       * se tiene que actualizar el grupo
       */
      const group = {
        id: this.selectedGroupObj.id,
        nombre_grupo: this.selectedGroupObj.nombre_grupo,
        sucursal: this.selectedGroupObj.sucursal
          ? this.selectedGroupObj.sucursal.id
          : null,
        salon_clases: this.selectedGroupObj.salon_clases
          ? this.selectedGroupObj.salon_clases.id
          : null,
        profesores: teachers,
        materia_profesor: subjectsTeacher,
        alumnos: students,
        imagen_grupo: this.selectedGroupObj.imagen_grupo
          ? this.selectedGroupObj.imagen_grupo.id
          : null,
        ciclo_escolar: this.selectedGroupObj.ciclo_escolar
          ? this.selectedGroupObj.ciclo_escolar.id
          : null,
        duracion_ciclo_escolar_meses:
          this.selectedGroupObj.duracion_ciclo_escolar_meses,
        institucion_educativa: this.selectedGroupObj.institucion_educativa
          ? this.selectedGroupObj.institucion_educativa.id
          : null,
        plan_estudios: this.selectedGroupObj.plan_estudios
          ? this.selectedGroupObj.plan_estudios.id
          : null,
        nivel_educativo: this.selectedGroupObj.nivel_educativo
          ? this.selectedGroupObj.nivel_educativo.id
          : null,
        estatus_sistema: this.selectedGroupObj.estatus_sistema,
      };
      console.log('data to send', group);

      const { ok, data, message } = await putGroup(group);
      console.log('data grupo', data);

      if (ok) {
        this.alertText = 'Profesor y materias asignadas exitosamente';
      } else {
        this.errors.push(
          'No se pudo asignar el profesor y materias al grupo. ' + message
        );
      }
    },
    async assignSyllabusToGroup() {
      if (
        this.selectedGroupObj &&
        !this.selectedGroupObj.plan_estudios &&
        this.selectedSyllabus
      ) {
        const { ok, message } = await postAssignSyllabusToGroup({
          id_grupo: this.selectedGroup,
          id_plan_estudios: this.selectedSyllabus,
        });
        if (ok) {
          this.alertText = 'Plan de estudios asignado a grupo exitosamente';
          // console.log('Plan de estudios asignado a grupo exitosamente', data);
        } else {
          this.warnings.push(message);
        }
      }
    },
    async createSubjectsStudent() {
      if (this.selectedGroup && this.createdSubjectsTeacher.length) {
        const { ok, data, message } =
          await postCreateSubjectStudentFromTeacherAssignement(
            this.selectedGroup,
            this.createdSubjectsTeacher
          );
        console.log(data);

        if (ok) {
          this.alertText = 'materiasAlumno creadas exitosamente';
        } else {
          this.warnings.push(message);
        }
      }
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.subjectTeacher = {
        profesor: null,
        materia: null,
        criterios_evaluacion: [],
        institucion_educativa: this.institutionId,
        estatus_sistema: true,
      };
      this.selectedSchoolYear = null;
      this.selectedSchoolLevel = null;
      this.selectedSyllabus = null;
      this.selectedTeacher = null;
      this.selectedGroup = null;
      this.selectedSubjects = [];
      this.createdSubjectsTeacher = [];
      this.groups = [];
    },
    removeChip(id) {
      this.syllabus.materias_curriculares =
        this.syllabus.materias_curriculares.filter((subject) => subject !== id);
    },
  },
};
